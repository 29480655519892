// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-barn-js": () => import("/opt/build/repo/src/pages/barn.js" /* webpackChunkName: "component---src-pages-barn-js" */),
  "component---src-pages-boker-og-blader-js": () => import("/opt/build/repo/src/pages/boker-og-blader.js" /* webpackChunkName: "component---src-pages-boker-og-blader-js" */),
  "component---src-pages-diverse-js": () => import("/opt/build/repo/src/pages/diverse.js" /* webpackChunkName: "component---src-pages-diverse-js" */),
  "component---src-pages-elektronikk-js": () => import("/opt/build/repo/src/pages/elektronikk.js" /* webpackChunkName: "component---src-pages-elektronikk-js" */),
  "component---src-pages-friluft-js": () => import("/opt/build/repo/src/pages/friluft.js" /* webpackChunkName: "component---src-pages-friluft-js" */),
  "component---src-pages-hjem-js": () => import("/opt/build/repo/src/pages/hjem.js" /* webpackChunkName: "component---src-pages-hjem-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-klaer-js": () => import("/opt/build/repo/src/pages/klaer.js" /* webpackChunkName: "component---src-pages-klaer-js" */),
  "component---src-pages-reise-js": () => import("/opt/build/repo/src/pages/reise.js" /* webpackChunkName: "component---src-pages-reise-js" */),
  "component---src-pages-skjonnhet-og-sminke-js": () => import("/opt/build/repo/src/pages/skjonnhet-og-sminke.js" /* webpackChunkName: "component---src-pages-skjonnhet-og-sminke-js" */),
  "component---src-pages-sport-js": () => import("/opt/build/repo/src/pages/sport.js" /* webpackChunkName: "component---src-pages-sport-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

